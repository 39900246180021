<template>
  <label v-if="label" class="form-label fw-bold text-dark fs-8

    " >
    {{ label }}
  </label>
  <Multiselect :canDeselect="false" :canClear="canClear"  style="min-height: 40px" v-model="data" :placeholder="placeholder" :options="options" :mode="mode" :searchable="searchable"
               :classes="classes" :closeOnSelect="closeOnSelect" :disabled="disabled" :class="_class" />
</template>
<script>
export default {

  props: {
    default_value: {
      default: '',
    },
    update_hook: {
      type: Function,
      default: () => { },
    },
    placeholder: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'single', // 'single'|'multiple'|'tags'
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [], // { value: '', label: '' }
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    _classes: {
      type: Object,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: true,
    }, 
    _class: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      data: this.default_value,
      classes: {
        'container': 'multiselect bg-light border-0',
        'containerActive': '',
        'wrapper': 'multiselect-wrapper  border-0',
        'search': 'multiselect-search  form-control form-control-sm form-control-solid ',
        'tagsSearchWrapper': 'multiselect-tags-search-wrapper  border-0',
        'tagsSearch': 'multiselect-tags-search  bg-light',
        'dropdown': 'multiselect-dropdown rounded border py-3 px-1 bg-solid z-index-2 min-h-250px',
        'options': 'multiselect-options',
        'multiselect-clear': 'z-index-0',
        'option': 'multiselect-option py-3',
        'tagWrapper': 'multiselect-tag-wrapper pe-2',
        'caret': 'multiselect-caret right-8px z-index-0',
        'placeholder': 'multiselect-placeholder text-start',
        'clearIcon': 'multiselect-clear-icon ms-2 z-index-0',
        'singleLabel': 'multiselect-single-label',
        'singleLabelText': 'multiselect-single-label-text',
        'clear': ' z-index-0 multiselect-clear',
        ...this._classes
      },
    }
  },

  watch: {
    data(val) {
      this.update_hook(val)
    },
  },
}
</script>
